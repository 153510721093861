<script>
import { Button, Table } from 'ant-design-vue'
import { delAction, getAction, postAction, putAction } from '@/command/netTool'
import DrawerForm from '../../../components/DrawerForm'
import apiTool from '@/command/apiTool'

export default {
  name: '',
  props: {},
  data() {
    return {
      dataSource: [],
      pageTotal: 8,
      pageCurrent: 1
    }
  },
  methods: {
    getFarmCouponActivity() {
      getAction(`/farmCouponActivity/page?size=8&current=${this.pageCurrent}`).then(res => {
        if (res.code == 200) {
          this.pageTotal = res.data.total
          this.dataSource = res.data.records
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'activityName',
          align: 'left',
          title: (
            <div>
              <span style="color:#f5222d">*</span>名称
            </div>
          )
        },
        {
          dataIndex: 'receiveNum',
          align: 'left',
          title: (
            <div>
              <span style="color:#f5222d">*</span>每人领取限额
            </div>
          )
        },
        {
          align: 'center',
          width: '10%',
          title: '操作',
          customRender: (text, records, index) => {
            return (
              <div>
                <Button
                  ghost={true}
                  type="primary"
                  style="margin-right: 5px"
                  onClick={() => {
                    getAction('/farmCouponActivity/detail/' + records.id).then(res => {
                      if (res.code == 200) {
                        this.handlePopup(res.data)
                      } else {
                        this.$message.error(res.msg)
                      }
                    })
                  }}
                >
                  编辑
                </Button>
                <a-popconfirm
                  title="确定删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  onConfirm={() => {
                    delAction('/farmCouponActivity', { ids: [records.id] }, '/api').then(res => {
                      if (res.code == 200) {
                        this.$message.success('操作成功')
                        this.getFarmCouponActivity()
                      } else {
                        this.$message.error(res.msg)
                      }
                    })
                  }}
                >
                  <Button ghost={true} type="danger" style="margin-left: 5px">
                    删除
                  </Button>
                </a-popconfirm>
              </div>
            )
          }
        }
      ]
    },
    getDrawerForm() {
      const formArr = [
        {
          form: [
            {
              name: '名称',
              type: 'input',
              key: 'activityName',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '每人领取限额',
              type: 'inputNumber',
              key: 'receiveNum',
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            }
          ]
        }
      ]
      return formArr
    },
    handlePopup(dataSource = {}) {
      let that = this
      apiTool.showDrawer({
        title: '活动集合',
        width: '720px',
        targetClass: 'active-class',
        view: DrawerForm,
        viewProps: {
          data: formData => that.getDrawerForm(dataSource, formData),
          form: dataSource
        },
        success: ({ data, setHidden }) => {
          if (!(data.activityName && data.receiveNum)) {
            this.$message.warning('请填写信息')
            return
          } else {
            if (dataSource.id) {
              putAction('/farmCouponActivity/edit', Object.assign({}, dataSource, data), '/api').then(res => {
                if (res.code == 200) {
                  this.$message.success('操作成功')
                  this.getFarmCouponActivity()
                  setHidden()
                } else {
                  this.$message.error(res.msg)
                }
              })
            } else {
              postAction('/farmCouponActivity/save', Object.assign({}, data), '/api').then(res => {
                if (res.code == 200) {
                  this.$message.success('操作成功')
                  this.getFarmCouponActivity()
                  setHidden()
                } else {
                  this.$message.error(res.msg)
                }
              })
            }
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    }
  },
  watch: {},
  mounted() {
    this.getFarmCouponActivity()
  },
  render() {
    return (
      <div>
        <Button type="primary" ghost={true} style="margin-bottom: 15px" onClick={() => this.handlePopup()}>
          添加
        </Button>
        <Table
          dataSource={this.dataSource}
          columns={this.getColumns()}
          pagination={{
            total: this.pageTotal,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: (page, pageSize) => {
              this.pageCurrent = page
              this.getFarmCouponActivity()
            }
          }}
        ></Table>
      </div>
    )
  }
}
</script>

<style lang="less" scoped></style>
